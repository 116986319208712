import logo from './img/VAT-background.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://linkedin.com/company/virage-teams"
          target="_blank"
          rel="noopener noreferrer"
        >
          Welcome to Virtual Agile Teams Ltd.
        </a>
        <img src={logo} alt="background"/>
      </header>
    </div>
  );
}

export default App;
